<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.company-registration.company-form"></custom-page-title>
                <h2>Elektronikus cégbejegyzési nyomtatványokra vonatkozó
                    követelmények</h2>

                <p>A cégnyilvántartás egyes kérdéseiről szóló 24/2006. (V. 18.)
                    IM rendelet szerint a cégbejegyzés, valamint a változásbejegyzés kérelmet
                    elektronikus nyomtatványon kell előterjeszteni. A nyomtatványok mintáját a
                    rendelet 1. számú melléklete tartalmazza.</p>

                <p>Az elektronikus cégbejegyzési eljárás és cégnyilvántartás
                    egyes kérdéseiről szóló 24/2006. (V. 18.) IM rendelet 5. §-a szerint
                    elektronikus cégeljárás esetén a kérelmet elektronikus nyomtatványon kell
                    előterjeszteni.</p>

                <p>Amennyiben nyomtatványkitöltő szoftvert szeretne fejleszteni, akkor a következő feltételeknek kell megfelelnie.</p>
                <ul>
                    <li>Az elektronikus nyomtatványokat XML formátumban, az alábbi sémadefiníciók valamelyikének megfelelően kell elkészíteni.<br>
                        <ul>
                            <li><b>2024. január 1-től</b> hatályos cégbejegyzési nyomtatványok sémadefiníciója:<br>
                                <a href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20240101.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20240101.xsd</a>
                                <br>
                            </li>
                            <li><b>2023. augusztus 20-tól 2024. március 31-ig</b> hatályos cégbejegyzési nyomtatványok sémadefiníciója:<br>
                              <a href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230820.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230820.xsd</a>
                              <br>
                            </li>
                        </ul>
                    </li>
                    <br>
                    <li>A sémadefiníció segítségével előállított xml fájl fejlécében szerepelnie kell a következő hivatkozásnak a stíluslapra:<br>
                        <ul>
                            <li><b>2024. január 1-től</b> hatályos cégbejegyzési nyomtatvány esetében:<br>
                                <code>&lt;?xml-stylesheet type=&quot;text/xsl&quot; href=&quot;https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20240101.xslt&quot;?&gt;</code><br>
                                A gyártófüggetlen megjelenítéshez használt stíluslap helye:<br>
                                <a href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20240101.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20240101.xslt</a>
                            </li>
                            <li><b>2023. augusztus 20-tól 2024. március 31-ig</b> hatályos cégbejegyzési nyomtatvány esetében:<br>
                              <code>&lt;?xml-stylesheet type=&quot;text/xsl&quot; href=&quot;https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230820.xslt&quot;?&gt;</code><br>
                              A gyártófüggetlen megjelenítéshez használt stíluslap helye:<br>
                              <a href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230820.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230820.xslt</a>
                            </li>
                        </ul>
                    </li>
                    <br>
                    <li>Java rendszerekbe integrálás során használható jar fájl helye:<br>
                        <ul>
                            <li><b>2024. január 1-től</b> hatályos cégbejegyzési nyomtatvány esetében:<br>
                                <a href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/microsec-cegbir-cegnyomtatvany-20240101-1.0.18.jar" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/microsec-cegbir-cegnyomtatvany-20240101-1.0.18.jar</a>
                            </li>
                            <li><b>2023. augusztus 20-tól 2024. március 31-ig</b> hatályos cégbejegyzési nyomtatvány esetében:<br>
                              <a href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/microsec-cegbir-cegnyomtatvany-20230820-1.0.15.jar" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/microsec-cegbir-cegnyomtatvany-20230820-1.0.15.jar</a>
                            </li>
                        </ul>
                    </li>
                    <br>
                    <li>A sémadefiníció segítségével előállított xml
                        fájl fejlécében ajánlott megjegyzésben feltüntetni a kitöltő program nevét és
                        verzióját:<br>
                        <code>&lt;!-- Kitöltő program neve verzió=2.1.1 --&gt;</code>
                    </li>
                </ul>
                <p></p>

                <h2>ÁFA nyilatkozat ellenőrzése</h2>

                <p>A következő linken letölthető a cégnyomtatványokon szereplő
                    ÁFA nyilatkozat ellenőrzésének cégbírósági programkód részlete.</p>

                <p><a
                    href="/afanyilatkozat/AFA20240101_javascript.txt" target="_blank">ÁFA
                    nyilatkozat ellenőrző programkód (Hatályos 2024. január 1-től)</a></p>

                <p>Megkérjük a cégnyomtatvány kitöltő szoftvert gyártókat, hogy
                    az ellenőrzési mechanizmust építsék be a szoftverükbe, figyelmeztessék a hibás
                    kitöltésre a jogi képviselőt, hogy a jogi képviselők lehetőleg már eleve ne
                    nyújtsanak be hibás ÁFA nyilatkozatot a cégbíróságokra.</p>

                <p>Amennyiben a cég hibásan tölti ki az ÁFA nyilatkozatot,
                    akkor a cégbíróság érkezteti a céget, de az APEH nem ad adószámot a cégnek.
                    Adószám hiányában azonban a céget a Ctv. szerint nem
                    lehet bejegyezni.</p>

                <h2>ÁFA nyilatkozat ellenőrző programkód története</h2>
                <ul>

                    <li><a
                        href="/afanyilatkozat/AFA20240101_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2024. január 1-től)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20230820_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2023. augusztus 20-tól 2023. december 31-ig)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20230101_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2023. január 1-től 2023. augusztus 19-ig)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20221001_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2022. október 1-től 2022. december 31-ig)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20210101_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2021. január 1-től 2022. szeptember 30-ig)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20200101_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2020. január 1-től 2020.
                        december 31-ig)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20190101_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2019. január 1-től 2019.
                        december 31-ig)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20180101_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2018. január 1-től 2018.
                        december 31-ig)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20170101_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2017. január 1-től 2017.
                        december 31-ig)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20160101_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2016. január 1-től 2016.
                        december 31-ig)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20150101_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2015. január 1-től 2015.
                        december 31-ig)</a></li>

                    <li><a
                        href="/afanyilatkozat/AFA20100101_javascript.txt" target="_blank">ÁFA
                        nyilatkozat ellenőrző programkód (Hatályos 2010. január 1-től 2014.
                        december 31-ig)</a></li>
                </ul>
                <p></p>

                <h2>Sémadefiníciók története</h2>
                <ul>
                    <li><i><b>Hatály: 2024. január 1-től</b></i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20240101.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20240101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20240101.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20240101.xslt</a><br><br></li>

                    <li><i><b>Hatály: 2023. augusztus 20-tól 2023. március 31-ig</b></i><br>
                    A cégbejegyzési nyomtatványok sémadefiníciója: <a
                        href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230820.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230820.xsd</a><br>
                    A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                        href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230820.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230820.xslt</a><br><br></li>

                  <li><i><b>Hatály: 2023. január 1-től 2023. november 30-ig</b></i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230101.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230101.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20230101.xslt</a><br><br></li>

                    <li><i><b>Hatály: 2022. október 1-től 2023. március 31-ig</b></i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20221001.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20221001.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20221001.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20221001.xslt</a><br><br></li>

                    <li><i><b>Hatály: 2022. október 1-től 2022. december 31-ig</b></i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20221001.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20221001.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20221001.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20221001.xslt</a><br><br></li>


                    <li><i><b>Hatály: 2022. szeptember 1-től 2022. december 31-ig</b></i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20220901.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20220901.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20220901.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20220901.xslt</a><br>
                        <br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20220901.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20220901.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20220901.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20220901.xslt</a><br><br></li>

                    <li><i><b>Hatály: 2021. április 1-től 2022. december 31-ig</b></i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20210401.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20210401.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20210401.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20210401.xslt</a><br>
                        <br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20210401.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20210401.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20210401.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany/cegnyomtatvany-20210401.xslt</a><br><br></li>

                    <li><i>Hatály: 2021. január 1-től 2021. augusztus 1-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20210101.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20210101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20210101.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20210101.xslt</a><br><br></li>


                    <li><i>Hatály: 2020. május 28-től 2021. március 1-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20200528.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20200528.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20200528.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20200528.xslt</a><br><br></li>


                    <li><i>Hatály: 2019. augusztus 17-től 2020. július 1-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20190817.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20190817.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20190817.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20190817.xslt</a><br><br></li>

                    <li><i>Hatály: 2019. január 1-től 2019. szeptember 16-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20190101.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20190101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20190101.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20190101.xslt</a><br><br></li>

                    <li><i>Hatály: 2018. szeptember 5-től 2019. február 4-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20180905.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20180905.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20180905.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20180905.xslt</a><br><br></li>


                    <li><i>Hatály: 2018. július 1-től 2018. szeptember 16-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20180701.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20180701.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20180701.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20180701.xslt</a><br><br></li>

                    <li><i>Hatály: 2017. június 8-tól 2018. szeptember 16-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20170608.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20170608.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20170608.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20170608.xslt</a><br><br></li>

                    <li><i>Hatály: 2017. január 1-től 2017. augusztus 2-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20170101.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20170101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20170101.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20170101.xslt</a><br><br></li>

                    <li><i>Hatály: 2016. január 1-től 2017. január 31-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20160101.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20160101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20160101.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20160101.xslt</a><br><br></li>

                    <li><i>Hatály: 2015. január 1-től 2016. január 31-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20150101.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20150101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20150101.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20150101.xslt</a><br><br></li>

                    <li><i>Hatály: 2014. március 15-től 2015. január 14-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20140315.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20140315.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20140315.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20140315.xslt</a><br><br></li>

                    <li><i>Hatály: 2014. január 1-től 2014. március 31-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20140101.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20140101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20140101.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20140101.xslt</a><br><br></li>


                    <li><i>Hatály: 2012. december 1-től 2014. március 31-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20121201.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20121201.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20121201.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20121201.xslt</a><br><br></li>


                    <li><i>Hatály: 2012. július 11-től 2012. december 31-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120711.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120711.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120711.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120711.xslt</a><br><br></li>


                    <li><i>Hatály: 2012. július 2-től 2012. július 20-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120702.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120702.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120702.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120702.xslt</a><br><br></li>


                    <li><i>Hatály: 2012. március 1-től 2012. július 20-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120301.xsd" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120301.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120301.xslt" target="_blank">https://schema.e-cegjegyzek.hu/schema/cegnyomtatvany20120301.xslt</a><br><br></li>


                    <li><i>Hatály: 2012. január 1-től 2012. február 29-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20120101.xsd" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20120101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20120101.xslt" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20120101.xslt</a><br><br></li>


                    <li><i>Hatály: 2011. január 21-től 2011. december 31-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20110101.xsd" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20110101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20110101.xslt" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20110101.xslt</a><br><br></li>


                    <li><i>Hatály: 2010. január 1-től 2011. december 31-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20100101.xsd" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20100101.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20100101.xslt" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20100101.xslt</a><br><br></li>


                    <li><i>Hatály: 2009. szeptember 1-től 2009. december 31-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20090901.xsd" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20090901.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20090901.xslt" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20090901.xslt</a><br><br></li>


                    <li><i>Hatály: 2008. december 30-tól 2009. szeptember 1-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20081230.xsd" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20081230.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20081230.xslt" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20081230.xslt</a><br><br></li>


                    <li><i>Hatály: 2008. július 1-től 2008. december 30-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20080701.xsd" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20080701.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20080701.xslt" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20080701.xslt</a><br><br></li>


                    <li><i>Hatály: 2008. június 14-től 2008. június 30-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20080610.xsd" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20080610.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20080610.xslt" target="_blank">https://www.e-cegjegyzek.hu/schema/cegnyomtatvany20080610.xslt</a><br><br></li>


                    <li><i>Hatály: 2008. január 1-től 2008. június 13-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://www.e-cegjegyzek.hu/schema/2008/cegnyomtatvany2008.xsd" target="_blank">https://www.e-cegjegyzek.hu/schema/2008/cegnyomtatvany2008.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://www.e-cegjegyzek.hu/schema/2008/cegnyomtatvany2008.xslt" target="_blank">https://www.e-cegjegyzek.hu/schema/2008/cegnyomtatvany2008.xslt</a><br><br></li>


                    <li><i>Hatály: 2007. december 31-ig</i><br>
                        A cégbejegyzési nyomtatványok sémadefiníciója: <a
                            href="https://www.e-cegjegyzek.hu/schema/2007/cegnyomtatvany2007.xsd" target="_blank">https://www.e-cegjegyzek.hu/schema/2007/cegnyomtatvany2007.xsd</a><br>
                        A gyártófüggetlen megjelenítéshez használt stíluslap helye: <a
                            href="https://www.e-cegjegyzek.hu/schema/2007/cegnyomtatvany2007.xslt" target="_blank">https://www.e-cegjegyzek.hu/schema/2007/cegnyomtatvany2007.xslt</a><br><br></li>

                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpCompanyForm extends Vue {
}
</script>

<style scoped>

</style>
